import type {ReactNode} from 'react';

import {useTranslations} from '@/hooks/useTranslations';
import {type Article, BLOG_PAGE_TYPE} from '@/components/blog/types';
import Hero from '@/components/pages/blog/Hero/Hero';
import LatestSection from '@/components/pages/blog/LatestSection/LatestSection';
import Conversion, {
  CtaStyles,
  HeadingStyles,
} from '@/components/pages/blog/SubscribeForm/Conversion/Conversion';
import ArticleCarousel from '@/components/pages/blog/ArticleCarousel/ArticleCarousel';

import TwoPane from './sections/TwoPane';
import ThreePane from './sections/ThreePane';

interface FeaturedSectionsIndexProps {
  featuredHeroArticle?: Article;
  heroArticles?: Article[];
  latestArticles?: Article[];
  category1Articles?: Article[];
  category2Articles?: Article[];
  miniCategory2Articles?: Article[];
  featuredCategory3Articles?: Article[];
  category3Articles?: Article[];
  category4Articles?: Article[];
  mostReadArticles?: Article[];
  settings: {
    [key: string]: {
      heading: string;
      subHeading?: string;
      leftSubHeading?: string;
      url: string;
      preferredBlogCardTag?: string;
    };
  };
  enBlogPath: string;
  blogType: BLOG_PAGE_TYPE;
  heroConversionCta?: ReactNode;
}

export default function FeaturedSectionsIndex({
  featuredHeroArticle,
  heroArticles = [],
  latestArticles = [],
  category1Articles = [],
  category2Articles = [],
  miniCategory2Articles = [],
  featuredCategory3Articles = [],
  category3Articles = [],
  category4Articles = [],
  mostReadArticles = [],
  settings = {},
  enBlogPath,
  blogType,
  heroConversionCta,
}: FeaturedSectionsIndexProps) {
  const {t} = useTranslations();

  return (
    <>
      <Hero
        blogType={blogType}
        featuredArticle={featuredHeroArticle}
        secondaryArticles={heroArticles}
        enBlogPath={enBlogPath}
        conversionCta={heroConversionCta}
      />
      {latestArticles.length > 0 && (
        <LatestSection
          blogType={blogType}
          sectionHead={t('latestArticles')}
          primaryArticles={latestArticles}
          enBlogPath={enBlogPath}
        />
      )}
      {(category1Articles.length > 0 || category2Articles.length > 0) && (
        <ThreePane
          topArticles={category1Articles}
          leftArticles={category2Articles}
          rightArticles={miniCategory2Articles}
          heading={settings.threePane.heading}
          subHeading={settings.threePane.subHeading || ''}
          leftSubHeading={settings.threePane?.leftSubHeading || ''}
          enBlogPath={enBlogPath}
          blogType={blogType}
        />
      )}
      <div className="border-y py-8 tablet:py-16 container">
        <Conversion
          {...t('conversion')}
          headingClassName={HeadingStyles}
          cta={{...t('conversionSubscription.conversionCta')}}
          mode="light"
          ctaClassName={CtaStyles({blogType})}
          subscriptionId="remove-me"
          blogType={blogType}
        />
      </div>
      {featuredCategory3Articles.length > 0 && (
        <TwoPane
          blogType={blogType}
          leftArticles={featuredCategory3Articles}
          rightArticles={category3Articles}
          heading={settings.twoPane.heading}
          categoryUrl={settings.twoPane.url}
          enBlogPath={enBlogPath}
        />
      )}
      {category4Articles.length > 0 && (
        <ArticleCarousel
          blogType={blogType}
          heading={settings.category4.heading}
          primaryArticles={category4Articles}
          enBlogPath={enBlogPath}
          link={{
            text: t('carouselSeeAllLinkText'),
            url: settings.category4.url,
          }}
          carouselName={settings.category4.url.split('/').pop()!}
          headingTag="h5"
          preferredBlogCardTag={
            settings.category4.preferredBlogCardTag ||
            settings.category4.heading
          }
        />
      )}
      {mostReadArticles.length > 0 && (
        <ArticleCarousel
          {...t('mostRead')}
          blogType={blogType}
          primaryArticles={mostReadArticles}
          enBlogPath={enBlogPath}
          carouselName="most-read"
          className="bg-[#F6F6F1]"
          headingTag={blogType === BLOG_PAGE_TYPE.ENTERPRISE ? 'h3' : 'h4'}
        />
      )}
    </>
  );
}
