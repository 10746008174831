import {cx} from 'class-variance-authority';

export const conversionHeadingStyles = cx(
  'tracking-[-.02em]',
  '[&_span]:font-aktivgroteskextended',
  '[&_span]:text-[20px] [&_span]:leading-[22px]',
  '[&_span]:uppercase [&_span]:tablet:normal-case',
  '[&_span]:tablet:text-xl',
  '[&_p]:text-sm',
);

export const conversionButtonStyles = cx(
  'mt-6',
  '[&_a]:bg-[#7BE986] [&_a]:text-[16px] [&_a]:leading-6',
  '[&_a]:px-5 [&_a]:py-2',
  '[&_p]:text-[10px] [&_p]:leading-[15px] [&_p]:pt-2.5',
  'hover:[&_a]:bg-[#62ba6b] focus:[&_a]:bg-[#00740B] active:[&_a]:bg-[#00740B]',
);
