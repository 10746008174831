import {useTranslations} from '@/hooks/useTranslations';
import Conversion from '@/components/pages/blog/SubscribeForm/Conversion/Conversion';
import {useIntlAcquisitionIncentive} from '@/hooks/experiments/useIntlAcquisitionIncentive';

import {conversionButtonStyles, conversionHeadingStyles} from './style';

export const BlogHeroConversion = ({
  сomponentName,
}: {
  сomponentName?: string;
}) => {
  const {t} = useTranslations();
  const {active, learnMore, creditAmount} = useIntlAcquisitionIncentive();

  const getSubHtml = () => {
    const conversionCta = t('conversionCta');
    if (active) {
      return {
        ...conversionCta,
        subheadHtml: t('acquisitionSubheadHtml', {
          learnMore,
          creditAmount,
        }),
      };
    }
    return conversionCta;
  };

  return (
    <div className="bg-black py-9" data-component-name={сomponentName}>
      <Conversion
        {...getSubHtml()}
        headingClassName={conversionHeadingStyles}
        buttonsClassName={conversionButtonStyles}
        className="mx-6 tablet:mx-9"
        mode="dark"
      />
    </div>
  );
};
